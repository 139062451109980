<template>
  <v-container
    id="login"
    class="fill-height"
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Real estate
              </h1>
            </div>
          </template>

          <v-card-text class="text-center">
            <ValidationObserver
              ref="login"
              tag="form"
              @submit.prevent="login"
            >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|email"
                name="email"
                vid="email"
              >
                <v-text-field
                  v-model="form.email"
                  color="secondary"
                  label="Email"
                  :error-messages="responseErrors.email ?? errors"
                  prepend-icon="mdi-email"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|min:8"
                name="password"
                vid="password"
              >
                <v-text-field
                  v-model="form.password"
                  class="mb-4"
                  color="secondary"
                  type="password"
                  label="Password"
                  :error-messages="responseErrors.password ?? errors"
                  prepend-icon="mdi-lock-outline"
                />
              </ValidationProvider>
              <pages-btn
                large
                color=""
                type="submit"
                depressed
                :loading="loadingSend"
                class="v-btn--text success--text"
              >
                Login
              </pages-btn>
            </ValidationObserver>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import auth from "../../../services/auth";

export default {
  name: 'Login',
  components: {
    PagesBtn: () => import('../components/Btn')
  },
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      responseErrors: {},
      loadingSend: false
    };
  },
  methods: {
    login () {
      auth.login(this.form)
        .then((res) => {if (res) this.$router.push({name: 'Search'});})
        .catch((err) => {if (err.status === 422) this.responseErrors = err.data.errors;})
    }
  },
  created() {
  }
};
</script>

